<template>
  <div class="page-layout" :class="{ 'page-layout--hidden': !showSidebarMenu }">
    <aside class="page-sidebar">
      <header class="page-sidebar__header">
        <div class="page-sidebar__header__title">{{ $tuf('pim') }}</div>
        <div
          class="page-sidebar__header__collapse"
          @click="showSidebarMenu = false"
        >
          &times;
        </div>
      </header>
      <nav class="page-sidebar__nav">
        <router-link
          v-for="(label, type) in types"
          :key="type"
          :to="{ name: 'pim-index', params: { type } }"
          class="page-sidebar__nav__link"
        >
          <span>{{ $tuf(type) }}</span>
          <svg
            class="page-sidebar__nav__link__icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 8 14"
          >
            <path
              d="M5.2 7l-5-5L1.6.6 8 7l-6.4 6.4-1.4-1.5z"
              fill="#1A282F"
              fill-rule="nonzero"
            />
          </svg>
        </router-link>
      </nav>
    </aside>
    <main class="page-main">
      <PageHeader
        class="relative z-20"
        :prefix="!showSidebarMenu ? $tuf('pim') : null"
        :title="typeLabel"
        :description="typeDescription"
        @click="showSidebarMenu = true"
      >
        <CrudFilter
          v-if="filterset[type]"
          ref="crudfilter"
          baseEntry="pim"
          :filters="filterset[type]"
          @change="onChangeFilters"
        />
        <router-link :to="{ name: 'pim-create' }" class="button button--opague">
          <span v-if="type === 'assets'">{{ $tuf('new_asset') }}</span>
          <span v-if="type === 'assetgroups'">{{ $tuf('new_assetgroup') }}</span>
          <span v-if="type === 'assettypes'">{{ $tuf('new_assettype') }}</span>
        </router-link>
      </PageHeader>

      <CrudTableGenerator
        v-if="showTable"
        class="relative z-10 px-5"
        ref="crudindex"
        baseEntry="pim"
        :endpoint="endpoint"
        :columns="columns[type]"
        :filters="filters"
        @total="setTotal"
        :allowExport="type === 'assets'"
      >
        <template v-slot:batch="{ selected }">
          <button v-if="type === 'assets'" class="mr-6 hover:text-prasset-green-500 focus:outline-none" type="button" @click="batchPublish(selected)">
            Publiceren
          </button>
        </template>

        <template v-if="type === 'assets'" v-slot:actions="{ row, routeTo }">
          <button @click="routeTo(row, 'contracts')" title="Bewoners" class="button button--clear button--small button--clamp border-gray-300 ri-passport-line mr-1" />
        </template>
      </CrudTableGenerator>
    </main>

    <RouterView /> <!-- for sidebar -->
  </div>
</template>

<script>
import { formatDateTime, formatPrice } from '@/providers/helpers';
import Api from '@/providers/api';
import CrudFilter from '@/modules/core/views/components/CrudFilter';
import CrudTableGenerator from '@/modules/core/views/components/CrudTableGenerator';
import PageHeader from '@/modules/core/views/components/PageHeader';

export default {
  name: 'PimIndex',

  components: {
    CrudFilter,
    CrudTableGenerator,
    PageHeader,
  },

  data() {
    return {
      showSidebarMenu: false,
      showTable: true,
      total: null,
      filters: {},
      filterset: {
        assets: [
          {
            type: 'select',
            label: 'Status',
            name: 'status',
            options: {
              AVAILABLE: 'Beschikbaar',
              UNDER_OFFER: 'Onder optie',
              UNDER_OPTION: 'In optie',
              WITH_RESERVATION: 'Gereserveerd',
              SOLD: 'Verkocht',
              RENTED: 'Verhuur',
            },
          },
          {
            type: 'select',
            label: 'Prijs',
            name: 'price_between',
            options: {
              '0,500': '€0 t/m €500',
              '500,1000': '€500 t/m €1000',
              '1000,1500': '€1000 t/m €1500',
            },
          },
          // {
          //   type: 'select',
          //   label: 'Asset type',
          //   name: 'assettype',
          //   endpoint: 'assettypes',
          // },
          {
            type: 'select',
            label: 'Categorie',
            name: 'assetgroup',
            endpoint: 'assetgroups',
          },
        ],
        assetgroups: [
          {
            type: 'text',
            label: 'Naam',
            name: 'name',
          },
        ],
        assettypes: [
          {
            type: 'text',
            label: 'Naam',
            name: 'name',
          },
        ],
      },
      types: {
        assets: 'Assets',
        assetgroups: 'Asset groepen',
        assettypes: 'Asset types',
      },
      typeDescriptions: {
        assets: this.$tuf('description_pim_assets'),
        assetgroups: this.$tuf('description_pim_assetgroups'),
        assettypes: this.$tuf('description_pim_assettypes'),
      },
      statuses: {
        AVAILABLE: 'Beschikbaar',
        UNDER_OFFER: 'Onder optie',
        UNDER_OPTION: 'In optie',
        WITH_RESERVATION: 'Gereserveerd',
        SOLD: 'Verkocht',
        RENTED: 'Verhuurd',
      },
      columns: {
        assets: [
          {
            sortable: true,
            key: 'state',
            label: 'Gepubliceerd',
            render: value => {
              const published = value === 'IS_PUBLISHED';
              return `
                <span class="status-dot status-dot--${published ? 'published' : 'unpublished'}"></span>
                <span class="ml-2">${published ? 'Ja' : 'Nee'}</span>
              `;
            },
          },
          {
            key: 'occupant',
            label: 'Bewoner',
            render: occupant => occupant ? occupant.name : '-',
          },
          {
            sortable: true,
            key: 'name',
            label: 'Name',
          },
          {
            sortable: true,
            key: 'status',
            label: 'Status',
            render: value => {
              return `
                <span class="status-dot status-dot--${(value || 'unknown').toLowerCase()}"></span>
                <span class="ml-2">${this.getStatus(value)}</span>
              `;
            },
          },
          // {
          //   sortable: true,
          //   key: 'assettype',
          //   label: 'Type',
          //   render: value => {
          //     return value ? value.name : '-';
          //   },
          // },
          {
            sortable: true,
            key: 'price',
            label: 'Prijs',
            render: value => formatPrice(value),
          },
          {
            sortable: true,
            key: 'asset_surface',
            label: 'Oppervlakte',
            render: value => {
              return value ? `${value} m²` : '-';
            },
          },
          {
            sortable: true,
            key: 'asset_volume',
            label: 'Inhoud',
            render: value => {
              return value ? `${value} &#13221;` : '-';
            },
          },
          {
            sortable: true,
            key: 'created_at',
            label: 'Aangemaakt op',
            render: (v) => formatDateTime(v),
          },
          {
            sortable: true,
            key: 'updated_at',
            label: 'Gewijzigd op',
            render: (v) => formatDateTime(v),
          },
        ],
        assetgroups: [
          {
            sortable: true,
            key: 'name',
            label: 'Naam',
          },
          {
            sortable: true,
            key: 'treename',
            label: 'Hiërarchie',
          },
          {
            sortable: false,
            key: 'assetcount',
            label: 'Aantal objecten',
            render: value => {
              return value ? value : 0;
            },
          },
        ],
        assettypes: [
          {
            sortable: true,
            key: 'name',
            label: 'Naam',
          },
          {
            sortable: true,
            key: 'assets',
            label: 'Aantal objecten',
            render: value => {
              return value ? value.length : 0;
            },
          },
        ],
      },
    };
  },
  methods: {
    setTotal(value) {
      this.total = value;
    },

    getStatus(status) {
      return this.statuses[status];
    },

    onChangeFilters(filters) {
      this.filters = filters;
      if (this.$refs.crudindex) {
        this.$refs.crudindex.setPage();
      }
    },

    async batchPublish(selected) {
      try {
        await Api().post(`${this.endpoint}/publish`, {
          ids: selected,
        });

        this.$notify({
          type: 'success',
          title: 'Publiceren',
          text: 'Geselecteerde records zijn gepubliceerd',
        });
        this.$refs.crudindex.clearSelection();
        this.$refs.crudindex.refreshPage();
      } catch {
        this.$notify({
          type: 'error',
          title: 'Publiceren',
          text: 'Er ging iets niet goed bij het publiceren',
        });
      }
    },
  },

  watch: {
    type() {
      this.filters = {};
      if (this.$refs.crudfilter) {
        this.$refs.crudfilter.resetFilters();
      }
      this.total = null;
      this.showTable = false;
      setTimeout(() => {
        this.showTable = true;
      }, 1);
    },
  },

  computed: {
    route() {
      return this.$route;
    },

    typeLabel() {
      return this.$tuf(this.type);
    },

    typeDescription() {
      return this.typeDescriptions[this.type] || null;
    },

    projectId() {
      return this.$route.params.project_id;
    },

    type() {
      return this.$route.params.type;
    },

    endpoint() {
      return `projects/${this.projectId}/${this.type}`;
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/crud";
</style>
