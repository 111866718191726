<template>
  <div class="page-layout" :class="{ 'page-layout--hidden': !showSidebarMenu }">
    <aside class="page-sidebar">
      <header class="page-sidebar__header">
        <div class="page-sidebar__header__title">{{ $tuf('crm') }}</div>
        <div class="page-sidebar__header__collapse" @click="showSidebarMenu = false">&times;</div>
      </header>
      <nav class="page-sidebar__nav">
        <router-link v-for="(label, type) in types" :key="type" :to="{ name: 'crm-index', params: { type } }" class="page-sidebar__nav__link">
          <span>{{ $tuf(type) }}</span>
          <svg class="page-sidebar__nav__link__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14">
            <path d="M5.2 7l-5-5L1.6.6 8 7l-6.4 6.4-1.4-1.5z" fill="#1A282F" fill-rule="nonzero"/>
          </svg>
        </router-link>
      </nav>
    </aside>
    <main class="page-main">
      <PageHeader
        class="relative z-20"
        :prefix="!showSidebarMenu ? $tuf('crm') : null"
        :title="typeLabel"
        :description="typeDescription"
        @click="showSidebarMenu = true"
      >
        <CrudFilter
          v-if="filterset[type]"
          baseEntry="crm"
          ref="crudfilter"
          :filters="filterset[type]"
          @change="onChangeFilters"
        />
        <router-link :to="{ name: 'crm-create' }" class="button button--opague">
          <span v-if="type === 'people'">{{ $tuf('new_person') }}</span>
        </router-link>
      </PageHeader>

      <CrudTableGenerator
        v-if="showTable"
        class="relative z-10 px-5"
        ref="crudindex"
        baseEntry="crm"
        :endpoint="endpoint"
        :columns="columns[type]"
        :filters="filters"
        @total="setTotal"
        :allowExport="type === 'people'"
      >
        <template v-if="type === 'people'" v-slot:actions="{ row, routeTo }">
          <button @click="routeTo(row, 'contracts')" title="Huisvesting" class="button button--clear button--small button--clamp border-gray-300 ri-home-4-line mr-1" />
        </template>
      </CrudTableGenerator>
    </main>

    <RouterView /> <!-- for sidebar -->
  </div>
</template>

<script>
import { formatDateTime } from '@/providers/helpers';
import CrudFilter from '@/modules/core/views/components/CrudFilter';
import CrudTableGenerator from '@/modules/core/views/components/CrudTableGenerator';
import PageHeader from '@/modules/core/views/components/PageHeader';

export default {
  components: {
    CrudFilter,
    CrudTableGenerator,
    PageHeader,
  },
  data() {
    return {
      showTable: true,
      showSidebarMenu: false,
      total: null,
      filters: {},
      filterset: {
        people: [
          {
            type: 'text',
            label: 'Achternaam',
            name: 'lastname',
          },
          {
            type: 'select',
            label: 'Geslacht',
            name: 'gender',
            options: {
              male: 'Man',
              female: 'Vrouw',
            },
          },
          {
            type: 'select',
            label: 'Leeftijd',
            name: 'aged_between',
            options: {
              '0,18': '0 t/m 18 jaar',
              '18,30': '18 t/m 30 jaar',
              '30,50': '30 t/m 50 jaar',
            },
          },
          {
            type: 'check',
            label: 'Met partner',
            name: 'with_partner',
            value: '1',
          },
        ],
      },
      types: {
        people: 'Personen',
      },
      typeDescriptions: {
        people: this.$tuf('description_crm_people'),
      },
      columns: {
        people: [
          {
            sortable: true,
            key: 'lastname',
            label: 'Achternaam',
          },
          {
            key: 'infix',
            label: 'Tussenv.',
          },
          {
            sortable: true,
            key: 'firstname',
            label: 'Voornaam',
          },
          {
            sortable: true,
            key: 'initials',
            label: 'Initialen',
          },
          {
            key: 'title',
            label: 'Titel',
          },
          {
            key: 'gender',
            label: 'Geslacht',
            render(v) {
              if (v === 'male') return 'Man';
              if (v === 'female') return 'Vrouw';
              return '-';
            },
          },
          {
            sortable: true,
            key: 'email',
            label: 'E-mailadres',
          },
          {
            sortable: true,
            key: 'created_at',
            label: 'Aangemaakt op',
            render: (v) => formatDateTime(v),
          },
          {
            sortable: true,
            key: 'updated_at',
            label: 'Gewijzigd op',
            render: (v) => formatDateTime(v),
          },
        ],
      },
    };
  },
  methods: {
    setTotal(value) {
      this.total = value;
    },

    onChangeFilters(filters) {
      this.filters = filters;
      if (this.$refs.crudindex) {
        this.$refs.crudindex.setPage();
      }
    },
  },
  watch: {
    type() {
      this.filters = {};
      if (this.$refs.crudfilter) {
        this.$refs.crudfilter.resetFilters();
      }
      this.total = null;
      this.showTable = false;
      setTimeout(() => {
        this.showTable = true;
      }, 1);
    },
  },
  computed: {
    typeLabel() {
      return this.$tuf(this.type);
    },
    typeDescription() {
      return this.typeDescriptions[this.type] || null;
    },
    projectId() {
      return this.$route.params.project_id;
    },
    type() {
      return this.$route.params.type;
    },
    endpoint() {
      return `projects/${this.projectId}/${this.type}`;
    },
  },
};
</script>

<style lang="scss">
  @import '@/scss/crud';
</style>
