<template>
  <div class="page-layout" :class="{ 'page-layout--hidden': !showSidebarMenu }">
    <aside class="page-sidebar">
      <header class="page-sidebar__header">
        <div class="page-sidebar__header__title">{{ $tuf('settings') }}</div>
        <div class="page-sidebar__header__collapse" @click="showSidebarMenu = false">&times;</div>
      </header>
      <nav class="page-sidebar__nav">
        <router-link v-for="(label, type) in types" :key="type" :to="{ name: 'settings-index', params: { type } }" class="page-sidebar__nav__link">
          <span>{{ $tuf(type) }}</span>
          <svg class="page-sidebar__nav__link__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14">
            <path d="M5.2 7l-5-5L1.6.6 8 7l-6.4 6.4-1.4-1.5z" fill="#1A282F" fill-rule="nonzero"/>
          </svg>
        </router-link>
      </nav>
    </aside>
    <main class="page-main">
      <PageHeader class="relative z-20"
        :prefix="!showSidebarMenu ? $tuf('settings') : null"
        :title="typeLabel"
        :description="typeDescription"
        @click="showSidebarMenu = true"
      >
        <router-link :to="{ name: 'settings-create' }" class="button button--opague">
          <span v-if="type === 'projects'">{{ $tuf('add_project') }}</span>
          <span v-if="type === 'users'">{{ $tuf('add_user') }}</span>
        </router-link>
      </PageHeader>

      <CrudTableGenerator
        v-if="showTable"
        class="relative z-10 px-5"
        ref="crudindex"
        baseEntry="settings"
        :endpoint="endpoint"
        :columns="columns[type]"
        :filters="filters"
        :actions="['edit', 'delete']"
        @total="setTotal"
        @change="onChangedRecords"
      />

      <RouterView /> <!-- for sidebar -->
    </main>
  </div>
</template>

<script>
import { projects, getProject, fetchProjects } from '@/compositions/useProjects';
import CrudTableGenerator from '@/modules/core/views/components/CrudTableGenerator';
import PageHeader from '@/modules/core/views/components/PageHeader';

export default {
  name: 'SettingsIndex',

  components: {
    CrudTableGenerator,
    PageHeader,
  },

  data() {
    return {
      showTable: true,
      showSidebarMenu: true,
      total: null,
      filters: {},
      types: {
        projects: 'Projects',
        users: 'Gebruikers',
      },
      typeDescriptions: {
        projects: this.$tuf('description_settings_projects'),
        users: this.$tuf('description_settings_users'),
      },
      columns: {
        projects: [
          {
            sortable: false,
            key: 'name',
            label: 'Naam',
          },
        ],
        users: [
          {
            sortable: false,
            key: 'name',
            label: 'Naam',
          },
          {
            sortable: false,
            key: 'email',
            label: 'email',
          },
        ],
      },
    };
  },

  methods: {
    setTotal(value) {
      this.total = value;
    },

    onChangeFilters(filters) {
      this.filters = filters;
      if (this.$refs.crudindex) {
        this.$refs.crudindex.setPage();
      }
    },

    async onChangedRecords() {
      await fetchProjects();

      let currentProjectFound = false;
      projects.value.filter(project => {
        if (project.id === this.projectId) {
          currentProjectFound = true;
        }
      });

      if (currentProjectFound) {
        getProject(this.projectId);
      } else if (projects.value.length > 0) {
        this.$router.replace(`/projects/${projects.value[0].id}/settings/projects`);
      } else {
        this.$router.replace({ name: 'project-create' });
      }
    },
  },

  watch: {
    type() {
      this.total = null;
      this.showTable = false;
      setTimeout(() => {
        this.showTable = true;
      }, 1);
    },
  },

  computed: {
    typeLabel() {
      return this.$tuf(this.type);
    },

    typeDescription() {
      return this.typeDescriptions[this.type] || null;
    },

    projectId() {
      return this.$route.params.project_id;
    },

    type() {
      return this.$route.params.type;
    },

    endpoint() {
      return this.$route.params.type;
    },
  },
};
</script>

<style lang="scss">
  @import '@/scss/crud';
</style>
