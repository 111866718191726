<template>
  <div class="page-layout" :class="{ 'page-layout--hidden': !showSidebarMenu }">
    <aside class="page-sidebar">
      <header class="page-sidebar__header">
        <div class="page-sidebar__header__title">{{ $tuf('forms') }}</div>
        <div class="page-sidebar__header__collapse" @click="showSidebarMenu = false">&times;</div>
      </header>
      <nav class="page-sidebar__nav">
        <router-link v-for="(label, type) in types" :key="type" :to="{ name: 'form-index', params: { type } }" class="page-sidebar__nav__link">
          <span>{{ $tuf(type) }}</span>
          <svg class="page-sidebar__nav__link__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14">
            <path d="M5.2 7l-5-5L1.6.6 8 7l-6.4 6.4-1.4-1.5z" fill="#1A282F" fill-rule="nonzero"/>
          </svg>
        </router-link>
      </nav>
    </aside>
    <main class="page-main">
      <PageHeader
        class="relative z-20"
        :prefix="!showSidebarMenu ? $tuf('forms') : null"
        :title="typeLabel"
        :description="typeDescription"
        @click="showSidebarMenu = true"
      >
        <CrudFilter
          v-if="filterset[type]"
          ref="crudfilter"
          baseEntry="form"
          :filters="filterset[type]"
          @change="onChangeFilters"
        />
        <router-link v-if="type !== 'formsubmissions'" :to="{ name: 'form-create' }" class="button button--opague">
          <span v-if="type === 'forms'">{{ $tuf('new_form') }}</span>
          <span v-if="type === 'formnotifications'">{{ $tuf('new_notification') }}</span>
        </router-link>
      </PageHeader>

      <CrudTableGenerator
        v-if="showTable"
        class="relative z-10 px-5"
        ref="crudindex"
        baseEntry="form"
        :endpoint="endpoint"
        :columns="columns[type]"
        :filters="filters"
        :actions="actions"
        @total="setTotal"
      >
        <template v-if="type === 'forms'" v-slot:actions="{ row }">
          <a class="button button--clear button--small text-xs ml-1" target="_blank" :href="`${previewUrl}/${row.id}`">{{ $t('preview') }}</a>
        </template>
      </CrudTableGenerator>
    </main>

    <RouterView /> <!-- for sidebar -->
  </div>
</template>

<script>
import { formatDateTime } from '@/providers/helpers';
import CrudFilter from '@/modules/core/views/components/CrudFilter';
import CrudTableGenerator from '@/modules/core/views/components/CrudTableGenerator';
import PageHeader from '@/modules/core/views/components/PageHeader';

export default {
  components: {
    CrudFilter,
    CrudTableGenerator,
    PageHeader,
  },
  data() {
    return {
      showTable: true,
      showSidebarMenu: true,
      total: null,
      filters: {},
      filterset: {
        forms: [
          {
            type: 'text',
            label: 'Naam',
            name: 'name',
          },
        ],
        formsubmissions: [
          {
            type: 'select',
            label: 'Formulier',
            name: 'form',
            endpoint: 'forms',
          },
        ],
        formnotifications: [
          {
            type: 'select',
            label: 'Formulier',
            name: 'form',
            endpoint: 'forms',
          },
        ],
      },
      types: {
        forms: 'Formulieren',
        formsubmissions: 'Inzendingen',
        formnotifications: 'Notificaties',
      },
      typeDescriptions: {
        forms: this.$tuf('description_form_forms'),
        formsubmissions: this.$tuf('description_form_formsubmissions'),
        formnotifications: this.$tuf('description_form_notifications'),
      },
      columns: {
        forms: [
          {
            sortable: true,
            key: 'name',
            label: 'Naam',
          },
          {
            sortable: true,
            key: 'submissions_count',
            label: 'Inzendingen',
          },
        ],
        formsubmissions: [
          {
            key: 'form',
            label: 'Formulier',
            render(v) {
              return v ? v.name : '-';
            },
          },
          {
            sortable: true,
            key: 'created_at',
            label: 'Ingezonden op',
            render: (v) => formatDateTime(v),
          },
        ],
        formnotifications: [
          {
            sortable: true,
            key: 'subject',
            label: 'Onderwerp',
          },
          {
            sortable: true,
            key: 'form',
            label: 'Formulier',
            render(v) {
              return v ? v.name : '-';
            },
          },
        ],
      },
    };
  },
  methods: {
    setTotal(value) {
      this.total = value;
    },

    onChangeFilters(filters) {
      this.filters = filters;
      if (this.$refs.crudindex) {
        this.$refs.crudindex.setPage();
      }
    },
  },
  watch: {
    type() {
      this.filters = {};
      if (this.$refs.crudfilter) {
        this.$refs.crudfilter.resetFilters();
      }
      this.total = null;
      this.showTable = false;
      setTimeout(() => {
        this.showTable = true;
      }, 1);
    },
  },
  computed: {
    typeLabel() {
      return this.$tuf(this.type);
    },
    typeDescription() {
      return this.typeDescriptions[this.type] || null;
    },
    projectId() {
      return this.$route.params.project_id;
    },
    type() {
      return this.$route.params.type;
    },
    endpoint() {
      return `projects/${this.projectId}/${this.type}`;
    },
    previewUrl() {
      return process.env.VUE_APP_FORM_URL;
    },
    actions() {
      if (this.type === 'formnotifications') {
        return ['edit', 'delete'];
      } else if (this.type === 'formsubmissions') {
        return ['view', 'delete'];
      } else {
        return ['view', 'edit', 'delete', 'custom'];
      }
    },
  },
};
</script>

<style lang="scss">
  @import '@/scss/crud';
</style>
