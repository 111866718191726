<template>
  <div class="bg-white">

    <PageHeader :title="$tuf('offer')" :description="$tuf('description_offer_collections')">
      <router-link :to="{ name: 'offer-create' }" class="button button--opague">
        {{ $tuf('add_offer') }}
      </router-link>
    </PageHeader>

    <CrudTableGenerator
      v-if="showTable"
      class="relative z-10 px-5"
      ref="crudindex"
      baseEntry="offer"
      :endpoint="endpoint"
      :columns="columns"
      :filters="filters"
      :actions="['edit', 'delete']"
      @total="setTotal"
      @change="onChangedRecords"
    >
      <template v-slot:actions="{ row }">
        <a class="button button--clear button--small text-xs ml-1" :href="`${previewUrl}/offer/${row.id}`" target="_blank">{{ $t('preview') }}</a>
      </template>
    </CrudTableGenerator>

    <RouterView /> <!-- for sidebar -->
  </div>
</template>

<script>
import { reactive, toRefs, computed } from '@vue/composition-api';
import CrudTableGenerator from '@/modules/core/views/components/CrudTableGenerator';
import PageHeader from '@/modules/core/views/components/PageHeader';

export default {
  name: 'OfferIndex',

  components: {
    CrudTableGenerator,
    PageHeader,
  },

  setup(props, { root, refs }) {
    const { project_id } = root.$route.params;
    const type = 'offercollections';

    const state = reactive({
      showTable: true,
      total: null,
      filters: {},
      columns: [
        {
          sortable: false,
          key: 'name',
          label: 'Naam',
        },
      ],
    });

    function setTotal(value) {
      state.total = value;
    }

    function onChangeFilters(filters) {
      state.filters = filters;
      if (refs.crudindex) {
        refs.crudindex.setPage();
      }
    }

    async function onChangedRecords() {
      //
    }

    const endpoint = `projects/${project_id}/${type}`;
    const previewUrl = process.env.VUE_APP_EMBED_URL;

    return {
      ...toRefs(state),
      endpoint,
      previewUrl,
      setTotal,
      onChangeFilters,
      onChangedRecords,
    };
  },
};
</script>

<style lang="scss">
  @import '@/scss/crud';
</style>
